nav {
    float: left;
    position: fixed;
    margin: 15% 3%;
    padding-right: 3%;
    border-right: 1px solid black;

    p {
        margin: 70% 10%;
        font-size: 1.1rem;
    }

    .grow {
        transition: all .2s ease-in-out;
        transform: scale(1.4);
    }

    .shrink {
        transition: all .2s ease-in-out;
        transform: scale(1.0);
    }
}