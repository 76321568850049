#skillsSmall {
    display: flex; 
    flex-direction: column;
    align-items: center;

    .skillButtons {
        width: 100%;
        padding: 3%;
        display: flex;
        justify-content: space-between;

        @media (min-width: 1px) and (max-width: 415px) {
            flex-direction: column;
            align-items: center;
        }
    
        button {
            position: relative;
            cursor: pointer;
            opacity: 0.9;
            padding: 1.5%;
            border-radius: 15px;
            border: 1px solid black;
            width: 210px;
            font-size: 1.4rem;
            outline: none;
            box-shadow: 8px 10px 10px 0;
            font-family: 'Bungee Inline', cursive;
            transition: all 0.2s;

            @media (min-width: 1px) and (max-width: 415px) {
                margin: 2%;
            }
        }
    
        #frontButton {
            background-color: #F65058FF;
        }
        #backButton {
            background-color: #FBDE44FF;
        }
        #stylingButton {
            background-color: rgb(77, 94, 129);
        }
    }

    .skillsContent {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    
        .skills {
            width: 110px;
            height: 140px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 4%;
            font-size: 1.3rem;
        }
    
        .front, .styling, .back {
            transition: all 0.8s;
            border-radius: 15px;
        }
        
        .front {
            background-color: #F65058FF;
        }
        .styling {
            background-color: rgb(77, 94, 129);
        }
        .back {
            background-color: #FBDE44FF;
        }

        .smallerText {
            font-size: 1.1rem;
        }
    }
}

