* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  max-width: 100%;
}

.App {
  text-align: center;
  padding: 2%;
  font-family: 'Open Sans', sans-serif;
}


@import "Titles";
@import "Home";
@import "HomeSmall";
@import "Nav";
@import "About";
@import "Projects";
@import "Skills";
@import "SkillsSmall";
@import "Hamburger";
@import "Contact";


@media (max-width: 1025px) {
    #homeDesktop, #skillsDesktop {
        display: none;
    }
}

@media (min-width: 1025px){
    #homeSmall, #skillsSmall {
        display: none;
    }
}