.hamburger {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 10%;
    position: fixed;
    right: 0;
    top: 0;

    &.open {
        background-color: #333;
    }

    ul {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: fixed;
        height: 90%;
        right: -240px;
        top: 15%;

        &.active {
            right: 0px;
            width: 10%;
            transition: 300ms ease all;
        }

        li {
            list-style-type: none;
            color: white;
            margin: 30%;
        }

        figure {
            height: 40px;
            width: 40px;
            color: #333;
            cursor: pointer;
            position: fixed;
            right: 2.5%;
            top: 3%;

            &.isWhite {
                color: white;
                transition: 300ms ease all;
            }
        }
    }
}

.hamburgerMobile {
    width: 20%;
    z-index: 9;

    ul {
        z-index: 9;

        &.active {
            padding-right: 2%;
            width: auto;
            z-index: 9;
            transition: 300ms ease all;
        }

        figure {
            padding-right: 5%;
            z-index: 9;
        }
    }
}

.hamburgerTablet { 
    z-index: 9;

    ul {
        z-index: 9;

        &.active {
            z-index: 9;
        }
    }
}