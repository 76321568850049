.titleh1 {
    font-size: 2.4rem;
    font-family: 'Bowlby One', cursive;
    border-bottom: 1px solid black;
    width: 33%;
    text-align: center;
    padding-bottom: 1%;

    @media (min-width: 1px) and (max-width: 1025px) {
        width: 75%;
        margin: 2%;
        margin-bottom: 3.5%;
    }
}

.aboutTitle {
    margin-left: 22%;

    @media (min-width: 1px) and (max-width: 415px) {
        margin-left: 10%;
    }

    @media (min-width: 416px) and (max-width: 1025px) {
        width: 55%;
    }
}

.projecth1 {
    margin: auto;

    @media (min-width: 416px) and (max-width: 1025px) {
        width: 50%;
    }
}

.skillsTitle { 
    @media (min-width: 416px) and (max-width: 1025px) {
        width: 40%;
    }
}

.titleh1 {
    margin: auto;
    width: 50%;
    margin-bottom: 5%;
}