#subtitle {
    padding-top: 1%;
    font-size: 1.2rem;
}

.projects {
    display: flex;
    flex-direction: column;
    margin: auto 20%;
    
    @media (min-width: 1px) and (max-width: 1024px) {
        margin: auto;
    }
    
    section {
        border: 1px solid black;
        display: flex;
        margin: 3% 0;

        @media (min-width: 1px) and (max-width: 415px) {
            flex-direction: column;
            margin: auto;
            padding: 2%;
            border: none;
        }

        .projectCard {
            height: 525px;
            width: 300px;
            cursor: pointer;

            @media (min-width: 1px) and (max-width: 415px) {
                margin: 2% auto;
            }

            .signLingo {
                background: url('../images/SignLingo.png');
            }

            .signLingoBack {
                background: url('../images/quiz.png');
                background-size: cover;
            }

            .sauti {
                background: url('../images/market.jpg');
            }

            .sautiBack {
                background: url('../images/code.png');
            }

            .nasa {
                background: url('../images/nasaFront.png');
                background-size: cover;
                background-position: center;
            }

            .nasaBack {
                background: url('../images/nasaBack.png');
                background-size: cover;
                background-position: center;
            }

            .projectCardWithImage {
                height: inherit;
            }
        }
  
        .description {
            min-width: 59.2%;
            background: #dee7eb;
            padding: 0 2%;
            text-align: left;

            @media (min-width: 1px) and (max-width: 415px) {
                border: 1px solid black;
            }

            .header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                h1 {
                    font-size: 2.5rem;
                }  

                .date {
                    padding-top: 10px;
                    padding-bottom: 5px;
                    text-emphasis: 900;
                }
            }
            
            h5 {
                margin-top: 1%;
                text-align: left; 
            }

            .descriptionText{
                font-size: .9rem;
                padding: 3% 0;
                text-align: left;
                text-indent: 25px;

                a {
                    color: black;
                }
            }
        }
    }
}

#sautiSection {
    @media (min-width: 1px) and (max-width: 415px) {
        display: flex;
        flex-direction: column-reverse;
    }
}