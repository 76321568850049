#contact {
    background-color: rgb(217, 222, 245);
    margin: 12% 20%;

    @media (min-width: 0px) and (max-width: 415px) {
        margin: 15% 2%;
    }

    @media (min-width: 416px) and (max-width: 1024px) {
        margin: 10% 10%;
    }
    
    form {
        display: flex;
        flex-direction: column;
        padding: 5% 2%;
        border: 1px solid #ccc;
        border-radius: 5px;

        input, textarea {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 1.5%;
            font-weight: 800;
            font-size: 0.9rem;
            font-family: 'Open Sans', sans-serif;
            border-radius: 0.45rem;
        }

        #mainInfoDiv {
            margin: 2% 0;
            display: flex;
            justify-content: space-between;

            @media (min-width: 0px) and (max-width: 415px) {
                flex-direction: column;
            }
            
            .mainInfo {
                width: 40%;
                resize: auto;

                @media (min-width: 0px) and (max-width: 415px) {
                    width: 100%;
                    margin: 1.5% auto;
                }
            }
        }

        button {
            margin-top: 2%;
            padding: 1.5%;
            border-radius: 5px;
            background-color: black;
            opacity: 0.85;
            color: white;
            font-size: 1rem;
            font-family: 'Open Sans', sans-serif;

            @media (min-width: 0px) and (max-width: 415px) {
               margin-top: 3%;
            }

            &:hover {
                opacity: 0.7;
            } 
        }
    }
}